/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials, and CTA
*
**********/

import React, { Fragment } from 'react';
import { Row, Hero, Features } from 'components/lib';

export function Home(props) {
  return (
    <Fragment>
      <Hero
        title='test your website quality in minutes.'
        tagline=''
      />
      <Row title='FEATURES' color='white'>
        <Features />
      </Row>
    </Fragment>
  );
}
