import React, { useEffect, useState } from 'react';
import { useParams ,useHistory} from 'react-router-dom';
import { Loader } from 'components/lib';
import {useLocation} from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';

import { useNavigate } from 'react-router-dom';

import { Card, Stat, ProgressBar, Chart, Table,
  Message, Grid, Animate, Feedback,useAPI } from 'components/lib';

import '../../css/result.css';

export function Freerun(props){

  

  const [state, setState] = useState({});
  //const data = useAPI('/api/result');

  const { id } = useParams();
  const [result, setResult] = useState([]);

  const [url, setUrl] = useState('');
  const [screenshoturl, setscreenshotUrl] = useState('');
  const [globalscore, setglobalScore] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [status, setStatus] = useState('');
  const [device, setDevice] = useState('');
  const [scores, setScores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [rules, setRules] = useState([]);
  const [metrics, setMetrics] = useState([]);

  //vitals 
  const [pagesize, setPagesize] = useState([]);
  const [pageload, setPageload] = useState([]);
  const [pagerequests, setPagerequests] = useState([]);
  const [pageinteractive, setPageinteractive] = useState([]);






  
  

  const base = 'https://beta.checkops.com';

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleZoom = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };


  // State to keep track of expanded rows
  const [expandedRows, setExpandedRows] = useState([]);

  // Function to toggle expansion of a row
  const toggleRowExpansion = (rowIndex) => {
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows(expandedRows.filter(row => row !== rowIndex));
    } else {
      setExpandedRows([...expandedRows, rowIndex]);
    }
  };

  const getScoreColor = (score) => {
    if (score >= 80) {
      return 'green';
    } else if (score >= 50) {
      return 'orange';
    } else {
      return 'red';
    }
  };


    const chartData = {
      labels: Object.keys(categories),
      datasets: [
        {
          data: Object.values(categories).map(categoryData => categoryData.categoryScore),
          backgroundColor: Object.values(categories).map(categoryData => {
            const score = categoryData.categoryScore;
            if (score >= 80) {
              return 'green';
            } else if (score >= 50) {
              return 'orange';
            } else {
              return 'red';
            }
          }),
        },
      ],
    };

  // Function to determine the grade based on the category score
const getGrade = (score) => {
  if (score > 80) {
    return 'A';
  } else if (score > 60) {
    return 'B';
  } else if (score > 40) {
    return 'C';
  } else if (score > 20) {
    return 'D';
  } else if (score > 0) {
    return 'E';
  } else {
    return 'F';
  }
};

// Function to get the SVG icon based on the grade
const getGradeIcon = (grade) => {
  // switch (grade) {
  //   case 'A':
  //     return <svg className="grade-icon" viewBox="0 0 24 24"><path d="M9 16.17l-4.17 4.17L3 18l6-6 9 9L21.17 15 9 2.83z"></path></svg>;
  //   case 'B':
  //     return <svg className="grade-icon" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M7 17.6L9.6 15 5 10.4l1.4-1.4L11 13.2l6.6-6.6L19 7z"></path></svg>;
  //   case 'C':
  //     return <svg className="grade-icon" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M20 12H4l8-8zM4 13h16l-8 8z"></path></svg>;
  //   case 'D':
  //     return <svg className="grade-icon" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M20 10h2v2h-2v-2zm0-6h2v2h-2V4zM2 4h2v2H2V4zm0 12v-2h2v2H2zm2-6H2v2h2v-2zm12 6h2v2h-2v-2zm0-6h2v2h-2v-2zM2 16v-2h2v2H2zm12-2h2v2h-2v-2zM4 6v2H2V6h2zm0 6H2v2h2v-2zm0-4H2V6h2v2zm12 0h-2V6h2v2zm0 4h-2v-2h2v2zm0 4h-2v-2h2v2z"></path></svg>;
  //   case 'E':
  //     return <svg className="grade-icon" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M13 9h3.55L13 5.45V9zM4.84 2.1L2.1 4.84l7.07 7.07V17H7v2h10v-2h-2v-5.17l7.07-7.07-2.74-2.73L19 5l-9 9-9-9 1.66-1.66z"></path></svg>;
  //   case 'F':
  //     return <svg className="grade-icon" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M4 4h16v16H4z"></path></svg>;
  //   default:
  //     return null;
  // }
};  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Call API 3
        const response = await fetch(`${base}/api/results/${id}`);
        if (!response.ok) {
          throw new Error('API 3 request failed');
        }

        const data = await response.json();
        setResult(data.scoreProfiles.generic.categories);
        setUrl(data.params.url);
        setDevice(data.params.options.device);

          setScores(data.scoreProfiles.generic.categories);
          setscreenshotUrl(base+data.screenshotUrl);
          setglobalScore(data.scoreProfiles.generic.globalScore);
          console.log(data);  
          
          setCategories(data.scoreProfiles.generic.categories);
          setRules(data.rules);

          setMetrics(data.toolsResults);
          setPagesize(data.toolsResults.redownload.metrics.totalWeight);
          setPageinteractive(data.toolsResults.phantomas.metrics.domInteractive);
          setPageload(data.toolsResults.phantomas.metrics.domComplete);
          setPagerequests(data.toolsResults.phantomas.metrics.requests);

          if (data.toolsResults.redownload.metrics.totalWeight >= 1024 * 1024) {
            setPagesize(`${(data.toolsResults.redownload.metrics.totalWeight / (1024 * 1024)).toFixed(2)} MB`);
          } else {
            setPagesize(`${(data.toolsResults.redownload.metrics.totalWeight / (1024)).toFixed(2)} KB`);
          }

          if (data.toolsResults.phantomas.metrics.domComplete >= 1000) {
            setPageload(`${(data.toolsResults.phantomas.metrics.domComplete / (1000)).toFixed(2)} seconds`);
          } else {
            setPageload(`${(data.toolsResults.phantomas.metrics.domComplete )} ms`);
          }
          
          if (data.toolsResults.phantomas.metrics.domInteractive >= 1000) {
            setPageinteractive(`${(data.toolsResults.phantomas.metrics.domInteractive / (1000)).toFixed(2)} seconds`);
          } else {
            setPageinteractive(`${(data.toolsResults.phantomas.metrics.domInteractive )} ms`);
          }


           
          

          




      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [id]);

  return(
  
    <Animate type='pop'>

     <Message
        closable
        title='Free Test Limit!'
        text='This is a just a free test with limited options ,for more features please signup and subscribe to our plans  '
        type='info'
      />


    <Grid cols='4'>
      <Stat
        loading={ url?.loading }
        value={url}
        label='URL'
        icon='link'
      />
        <Stat
        loading={ globalscore?.loading }
        value={`${ globalscore }/100`}
        label='Overall Score'
        icon='trending-up'
        change='2%'
      />
       <Stat
        loading={ globalscore?.loading }
        value={getGrade(globalscore)}
        label='Overall Grade'
        icon='trending-up'
      />
      <Stat
        loading={ device?.loading }
        value={ device}
        label='Device Type'
        icon='monitor'
      />
       <Stat
        loading={ pagesize?.loading }
        value={ pagesize}
        label='Page Size'
        icon='package'
      />
      <Stat
        loading={ device?.loading }
        value={ pageinteractive}
        label='Page Interactive Time'
        icon='download'
      />
      <Stat
        loading={ pageload?.loading }
        value={ pageload}
        label='Page Load Time '
        icon='download-cloud'
      />
      
       <Stat
        loading={ pagerequests?.loading }
        value={ pagerequests}
        label='Page Requests'
        icon='list'
      />
      
    </Grid>


    <Grid cols='2'>
      <Card title='Categories Score' loading={ categories.loading }>
          {Object.entries(categories).map(([category, categoryData]) => (
            <ProgressBar
              key={ categoryData.label }
              label={`${categoryData.label} - ${categoryData.categoryScore}/100`}
              progress={ categoryData.categoryScore + '%'}
            />
        )
)}
        
      </Card>
      <Card title='Screenshot'>
      <div class="screenshot">
          <img src={screenshoturl} alt="Screenshot" />
      </div>
      </Card>
    </Grid>

    <Card title='Score Details' last>

    <div className="container">
  {Object.entries(categories).map(([category, categoryData]) => {
    const grade = getGrade(categoryData.categoryScore);

    return (
      <section key={category} className="category">
        <div className={`category-info ${grade}`}>
          <h2>{categoryData.label}</h2>
          <p> Score: {categoryData.categoryScore}/100</p>
          <div className="grade-icon">{getGradeIcon(grade)}</div>
        </div>
        <div className="checklist">
          {categoryData.rules.map((rule, ruleIndex) => {
            const ruleData = rules[rule];
            const isExpanded = expandedRows[ruleIndex];
            const ruleIcon = ruleData.bad ? (
              <span className="cross">✕</span>
            ) : (
              <span className="check">✓</span>
            );

            const toggleExpansion = () => {
              const newExpandedRows = { ...expandedRows };
              newExpandedRows[ruleIndex] = !isExpanded;
              setExpandedRows(newExpandedRows);
            };

            let displayValue = ruleData.value;

            if (ruleData.policy && ruleData.policy.unit) {
              if (ruleData.policy.unit === 'bytes') {
                if (ruleData.value >= 1024 * 1024) {
                  displayValue = `${(ruleData.value / (1024 * 1024)).toFixed(2)} MB`;
                } else {
                  displayValue = `${(ruleData.value / 1024).toFixed(2)} KB`;
                }
              } else if (ruleData.policy.unit === 'ms') {
                if (ruleData.value >= 1000) {
                  displayValue = `${(ruleData.value / (1000)).toFixed(2)} Seconds`;
                } else {
                  displayValue = `${ruleData.value} ms`;
                }

                
              }
            }

            return (
              <div className="checklist-item" key={rule}>
                <div className="checklist-header" onClick={toggleExpansion}>
                  <div className="rule-details">
                    <span className="label">{ruleData.policy.label}</span>
                    <span className="value">{displayValue}</span>
                    <span className="score">{ruleData.score}/100</span>
                  </div>
                  <div className="icon">{ruleIcon}</div>
                </div>
                {isExpanded && (
                  <div className="details">
                    <div className="message">{ruleData.policy.message}</div>
                    <a className="link" href="https://app.monkeytest.ai/signup?href=ruledetails" rel="noopener noreferrer">
                      signup for more details
                    </a>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </section>
    );
  })}
</div>




    </Card>

    <Feedback />

  </Animate>




  );
}
